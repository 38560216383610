import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";

export default async function GetUSDTBalance(id, networkType) {
  console.log(networkType, "..balance type");

  const { ethereum } = window;
  if (!ethereum) {
    console.error("Ethereum object not found");
    return;
  }

  try {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

    let contractAddress, contractABI;

    if (networkType === "Mainnet") {
      contractAddress = ContractDetails.BUSD;
      contractABI = ContractDetails.BUSD_ABI;
    } else if (networkType === "Testnet") {
      contractAddress = ContractDetails.USDT;
      contractABI = ContractDetails.USDT_ABI;
    } else {
      console.error("Unsupported network type");
      return;
    }

    const contractInstance = new ethers.Contract(
      contractAddress,
      contractABI,
      signer
    );
    const balance = await contractInstance.balanceOf(id);

    return balance;
  } catch (error) {
    console.log("Error in GetUSDTBalance:", error);
  }
}
