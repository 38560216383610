import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import "./MyChart.css";
import { Data } from "../../Common/Data";
import { Link } from "react-router-dom";
const UsdtChart = (props) => {
  // Data.isDebug && console.log("data of usdt", props);
  const root = document.documentElement;
  const rootStyles = getComputedStyle(root);
  const rootColor = rootStyles.getPropertyValue("--colorPrimary");
  const chartMinedValue = props?.stake - props?.mined;
  const data = [
    {
      title: "Mined",
      value: parseFloat(props?.mined ?? 0.00000000001),
      color: rootColor,
    },
    {
      title: "Staking",
      value: parseFloat(chartMinedValue ?? 0.00000000001),
      color: "grey",
    },
  ];

  return (
    <div className="capping">
      <div className="cappingGraphDiv">
        <div style={{ height: "120px" }}>
          <PieChart
            animate={true}
            animationDuration={2000}
            data={data}
            lineWidth={30}
            paddingAngle={2}
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fill: "#fff",
              fontSize: "5px",
              fontFamily: "sans-serif",
              fontSize: "8px",
              fontWeight: "bold",
              borderRadius: "50%",
              background: "red !important",
            }}
          />
        </div>
        <div className="cappingAbout ">
          <div>
            <span style={{ background: "grey" }}></span>
            <p>Staking</p>
          </div>
          <div>
            <span style={{ background: rootColor }}></span>
            <p>Mined</p>
          </div>
        </div>
      </div>

      <div className="cappingDetails cappingDetailsDashboard">
        <div>
          <div>
            <h1>{props?.stake} USDT</h1>
            <p>Total Staking</p>
          </div>
          <div>
            <h1>{props?.mined} USDT</h1>
            <p>Total Mined</p>
          </div>
        </div>
        <div>
          <div>
            <h1>{parseFloat(props?.claimed ?? "0.00000000001")} USDT</h1>
            <p>Claimed</p>
          </div>
          <div>
            <h1>{parseFloat(props?.mined - props?.claimed).toFixed(2)} USDT</h1>
            <p>Pending</p>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="stakeButton">
          <Link to="usdt_plan" className="flex-1" style={{ width: "100%" }}>
            <button style={{ padding: "10px 50px" }} className="btnPrimary">
              Stake
            </button>
          </Link>
          <Link to="usdt_claim" className="flex-1" style={{ width: "100%" }}>
            <button style={{ padding: "10px 50px" }} className="btnPrimary">
              Claim
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UsdtChart;
// animationDuration: number;
// animationEasing: string;
// center: [number, number];
// data: Data < BaseDataEntry >;
// labelPosition: number;
// lengthAngle: number;
// lineWidth: number;
// paddingAngle: number;
// radius: number;
// startAngle: number;
// viewBoxSize: [number, number];
