import BUSD_ABI from "../Contracts/BUSD_ABI.json";
import contractABI from "../Contracts/contract_ABI.json";
import tokenABI from "../Contracts/Token_ABI.json";
import contractABI_usdt from "../Contracts/contract_ABI_usdt.json";
// const arr  = {contract:"0x7928986fe9728c35cD49a0240281f03351Ce5672",contractABI:contractABI,BUSD:"0xe37b70Ef457899F0afdFB71CEd2671CFA84ef770",BUSD_ABI:BUSD_ABI}
import USDT_ABI from "../Contracts/USDTABI.json";
const arr = {
  contract: "0xFeb884F8acd8D9E87b44145E464fD266e1745166",
  contractABI: contractABI,
  BUSD: "0x55d398326f99059fF775485246999027B3197955",
  BUSD_ABI: BUSD_ABI,
  token: "0x476Fa8a1cf60D0Bd6a50BF9d00529B5b57340363",
  tokenABI: tokenABI,
  contract_usdt: "0xF5BD61Ab1390637242cA22a66d39548d6E2e7E3c",
  contractABI_usdt: contractABI_usdt,
  USDT: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
  USDT_ABI: USDT_ABI,
};

// const arr = {
//   contract: "0x98F7A9bB657dE1a6E7BaD6655483850c74A878e1",
//   contractABI: contractABI,
//   BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
//   BUSD_ABI: BUSD_ABI,
//   token: "0xa30C22b19fCd4A7209d9E55e15D890a350c6Ae4F",
//   tokenABI: tokenABI,
// };

export const ContractDetails = arr;

// Action creators are generated for each case reducer function

export default ContractDetails;
