import React, { useEffect, useState } from "react";
import "./Claim.css";
import GetFloatValue from "../../Common/GetFloatValue";
import ClaimFund from "../../Common/Claim";
import Loader from "../../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Data } from "../../Common/Data";
import ContractDetails from "../../Contracts/ContractDetails";
import { ethers } from "ethers";
import GetUserMined from "../../Common/GetUserMined";
import userClaimed from "../../Common/UserClaimed";
import getAddress from "../../Common/GetAddress";
import GetChainId from "../../Common/GetChainId";
import { ApiPaths } from "../../Config";
import axios from "axios";

const Claim = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [tokenMined, setTokenMined] = useState("");
  const [tokenClaimed, setTokenClaimed] = useState("");
  const [tokenPending, setTokenPending] = useState("");
  const [loading, setLoading] = useState(false);
  const [claimStatus, setClaimStatus] = useState(false);

  useEffect(() => {
    checkData();
    FetchPlanData();
  }, []);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);

  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    const data = JSON.parse(jsondata);
    if (data) {
      setWalletAddress(data?.profile?.[0]?.wallet_address);
      fetchContractData(data?.profile?.[0]?.wallet_address);
    } else {
      alert("Address not found");
    }
  }
  async function fetchContractData(address) {
    try {
      setLoading(true);
      let tokenMined = await GetUserMined(address);
      let tokenClaimed = await userClaimed(address);
      let pending = tokenMined - tokenClaimed;
      setTokenMined(tokenMined);
      setTokenClaimed(tokenClaimed);

      if (tokenClaimed <= tokenMined) {
        setTokenClaimed(tokenClaimed);
        setTokenPending(0.0);
      } else {
        setTokenPending(GetFloatValue(pending));
        setTokenClaimed(tokenClaimed);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  // async function Claim() {
  //   if (walletAddress) {
  //     if (tokenPending > 0) {
  //       setLoading(true);
  //       const result = await ClaimFund(walletAddress);
  //       console.log(result);
  //       if (result) {
  //         toastSuccess("Claimed Successfull");
  //         fetchContractData(walletAddress);
  //         setLoading(false);
  //       } else {
  //         toastFailed("Transaction Failed!");
  //         setLoading(false);
  //       }
  //     } else {
  //       toastFailed("Insufficient Fund");
  //     }
  //   } else {
  //     toastFailed("Transaction Failed!");
  //   }
  // }
  // async function ClaimForUser(id) {
  //   setLoading(true);
  //   try {
  //     const bscTestnetRpcUrl = Data.providerLink; // BSC Testnet endpoint
  //     // Private key of the account
  //     const privateKey = Data.privateKey;
  //     // Create a provider instance
  //     const provider = new ethers.providers.JsonRpcProvider(bscTestnetRpcUrl);
  //     // Create a wallet instance from the private key and provider
  //     const wallet = new ethers.Wallet(privateKey, provider);

  //     // Contract ABI and address
  //     const contractABI = ContractDetails.contractABI;
  //     const contractAddress = ContractDetails.contract; // Address of the deployed contract

  //     // Connect to the deployed contract
  //     const contract = new ethers.Contract(
  //       contractAddress,
  //       contractABI,
  //       wallet
  //     );
  //     console.log("contract", contract);
  //     // Function to interact with the contract

  //     // Call the contract function with custom gas price and gas limit
  //     const result = await contract.claimForUser(
  //       walletAddress,
  //       {
  //         gasPrice: ethers.utils.parseUnits("10", "gwei"), // Example gas price: 10 Gwei
  //         gasLimit: 2000000, // Example gas limit: 2 million
  //       }
  //     );
  //     alert("Transaction successful");
  //     setLoading(false);
  //     fetchContractData(walletAddress)
  //     console.log("Transaction successful. Result:", result);
  //   } catch (error) {
  //     toastFailed("Transaction Failed! Please check your details");
  //     console.error("Error calling contract function:", error);
  //     setLoading(false);
  //   }
  // }
  async function claim() {
    setLoading(true);
    try {
      let chain = GetChainId();
      if (chain) {
        const connectedAddress = await getAddress();
        Data.isDebug &&
          console.log("000000000000", {
            connectedAddress,
            walletAddress,
          });
        const { ethereum } = window;
        if (ethereum) {
          if (connectedAddress == walletAddress) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const busdInstance = new ethers.Contract(
              ContractDetails.contract,
              ContractDetails.contractABI,
              signer
            );
            let inc = await busdInstance.claim({
              value: ethers.utils.parseEther("0"),
            });
            await inc.wait();
            fetchContractData(walletAddress);
            toastSuccess("Claimed Successfull");
          } else {
            toastFailed(
              "Please connect with registered wallet address" + walletAddress
            );
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      alert("Transaction Failed");
      Data.isDebug && console.log("error", error);
      setLoading(false);
    }
  }
  function FetchPlanData() {
    setLoading(true);
    let token = localStorage.getItem("token");
    // console.log("token____address", token);
    axios({
      method: "get",
      url: ApiPaths.clainStatus,
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
      },
    })
      .then(function (response) {
        Data.isDebug && console.log("plan data", response);
        if (response?.data?.res == 0) {
          setClaimStatus(true);
        }
        setLoading(false);
      })

      .catch(function (response) {
        // console.log(response);
        setLoading(false);
      });
  }

  return (
    <>
      <section className="dashboard">
        {loading && <Loader />}
        <ToastContainer autoClose={1500} />
        <h1 className="textHeadingWithMargin">MJK Staking</h1>
        <div className="claimDiv">
          <div>
            <h1>{tokenMined}</h1>
            <p>Coin Mined</p>
          </div>
          <div>
            <h1>{tokenClaimed}</h1>
            <p>Total Claimed</p>
          </div>
          <div>
            <h1>{tokenPending}</h1>
            <p>Pending</p>
          </div>
          <div>
            {/* <button onClick={() => claim()}>Claim</button> */}

            {claimStatus == false ? (
              <button style={{ filter: "grayScale(1)" }}>Claim</button>
            ) : (
              <button onClick={() => claim()}>Claim</button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Claim;
