import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "./Slot.css";
import { Row, Col } from "react-bootstrap";
import { FaTelegramPlane } from "react-icons/fa";
import Logo from "./../../Images/logo.png";
import Wallet from "./../../Images/wallet.png";
import Meating from "./../../Images/meeting.png";
import Team from "./../../Images/team.png";
import { ApiPaths } from "../../Config";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import InfoPage from "../../Components/InfoPage/InfoPage";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Data } from "../../Common/Data";
import MyChart from "../../Components/MyChart/MyChart";
import GetFloatValue from "../../Common/GetFloatValue";
import getBalance from "../../Common/GetBalance";
import { FiCopy } from "react-icons/fi";
import CopyFromtag from "../../Common/CopyFromtag";
import liveRate from "../../Common/LiveRate";
import AllUserStakes from "../../Common/AllUserStakes";
import ReferralComponent from "../../Components/ReferralComponent";
import getUserStakes from "../../Common/GetUserStakes";
import GetUserMined from "../../Common/GetUserMined";
import userClaimed from "../../Common/UserClaimed";
import GetUserData from "../../Common/GetUserData";
import UsdtStakes from "../../Common/UsdtStake";
import UsdtMined from "../../Common/GetMined";
import UsdtClaimed from "../../Common/GetClaimed";
import UsdtChart from "../../Components/MyChart/UsdtChart";
import AllStakeForUSDT from "../../Common/AllStakeForUSDT";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [repurchaseToken, setRepurchaseToken] = useState(0);
  const [dashboardData, setDashboardData] = useState([]);
  const [myRank, setMyRank] = useState();
  const [incomeInfo, setIncomeInfo] = useState(false);
  const [incomeHeading, setIncomeHeading] = useState(false);
  const [infoData, setInfoData] = useState();
  const [tokenStake, setTokenStake] = useState(0);
  const [tokenMined, setTokenMined] = useState(0);
  const [tokenClaimed, setTokenClaimed] = useState(0);
  const [walletBalance, setWalletBalance] = useState([0]);
  const [dashboardLiveRate, setDashboardLiveRate] = useState(0);
  const [usdtStake, setUsdtStake] = useState(0);
  const [usdtMine, setUsdtMine] = useState(0);
  const [usdtClaim, setUsdtClaim] = useState(0);
  const [usdtStakesData, setUsdtStakesData] = useState([]);
  let x = 0;
  useEffect(() => {
    if (x === 0) {
      checkData();
      x = 1;
    }
  }, []);

  const toastSuccess = (msg) => toast.success(msg);
  const toastFailed = (msg) => toast.error(msg);
  function IncomeInfoState(newstate) {
    setIncomeInfo(newstate);
  }

  function InfoPageData(heading, data) {
    Data.isDebug && console.log(heading);
    setIncomeHeading(heading);
    setInfoData(data);
    setIncomeInfo(true);
  }

  async function calculateAllStaking(stakeData) {
    let count = 0;
    for (let i = 0; i < stakeData.length; i++) {
      count += parseFloat(stakeData?.[i]?.stakedToken) / 1e18;
    }
    return parseFloat(count).toFixed(2);
  }

  async function getUsdtAllStakes(address) {
    const tempUserStakes = await AllStakeForUSDT(address);
    console.log(
      "111111111111111111111111111111111111111111111111",
      tempUserStakes
    );
    setUsdtStakesData(tempUserStakes);
  }
  async function getBalanceFunction(address) {
    const balance = await getBalance(address);
    setWalletBalance(balance);
  }
  async function GetUserMinedFunction(address) {
    let getUserMined = await GetUserMined(address);
    setTokenMined(getUserMined);
  }
  async function userClaimedFunction(address) {
    let getUserClaimed = await userClaimed(address);
    setTokenClaimed(getUserClaimed);
  }
  async function GetUserDataFunction(address) {
    let userData = await GetUserData(address);
    setRepurchaseToken(GetFloatValue(userData?.repurchaseWallet / 1e18, 2));
  }
  async function liveRateFunction(address) {
    const contractLiveRate = await liveRate(address);
    setDashboardLiveRate(contractLiveRate);
  }
  async function AllUserStakesFunction(address) {
    console.log(address);
    const allStaking = await AllUserStakes(address);
    console.log("allStakes", allStaking);
    let StakingCount = await calculateAllStaking(allStaking);
    setTokenStake(StakingCount);
  }
  async function UsdtStakeFunction(address) {
    console.log(address, "usdtaddress.........");
    const stake = await UsdtStakes(address);
    console.log(stake, "stake data");
    let StakingCount = await calculateAllStaking(stake);
    setUsdtStake(StakingCount);
  }
  async function UsdtMinedFunction(address) {
    let usdtMined = await UsdtMined(address);
    console.log("usdt mined", usdtMined);
    setUsdtMine(usdtMined);
  }
  async function UsdtClaimedFunction(address) {
    let usdtclaim = await UsdtClaimed(address);
    console.log("usdt claimmmmmmmmmmmmmmmmmmmmmmmmmmm", usdtclaim);

    setUsdtClaim(usdtclaim);
  }
  async function fetchContractData(address) {
    try {
      liveRateFunction(address);
      AllUserStakesFunction(address);
      getBalanceFunction(address);
      GetUserMinedFunction(address);
      userClaimedFunction(address);
      GetUserDataFunction(address);
      UsdtMinedFunction(address);
      UsdtStakeFunction(address);
      UsdtClaimedFunction(address);
      getUsdtAllStakes(address);
    } catch (e) {
      console.log(e);
    }
  }
  // async function fetchContractData(address) {
  //   const contractLiveRate = await liveRate(address);
  //   setDashboardLiveRate(contractLiveRate);
  //   try {
  //     const allStaking = await AllUserStakes(address);
  //     console.log("allStakes", allStaking);

  //     let StakingCount = calculateAllStaking(allStaking);

  //     const balance = await getBalance(address);
  //     setWalletBalance(balance);
  //     const contractdata = await getContractData(address);

  //     const stakeData = contractdata?.allUserStakes;
  //     console.log("userData", contractdata);
  //     setTokenStake(StakingCount);
  //     setTokenMined(GetFloatValue(contractdata?.userMined / 1e18, 2));
  //     setTokenClaimed(GetFloatValue(contractdata?.userClaimed / 1e18, 2));
  //     setTotalStaking(GetFloatValue(contractdata?.totalStaking / 1e18));
  //     setTotalUsers(String(contractdata?.totalUsers));
  //     setRepurchaseToken(GetFloatValue(contractdata?.userData?.repurchaseWallet / 1e18, 2));
  //     const contractAllData = {
  //       tokenStake: StakingCount,
  //       tokenMined: GetFloatValue(contractdata?.userMined / 1e18, 2),
  //       tokenClaimed: GetFloatValue(contractdata?.userClaimed / 1e18, 2),
  //       tokenStaking: GetFloatValue(contractdata?.totalStaking / 1e18),
  //       totalUsers: String(contractdata?.totalUsers),
  //       liveRate: contractLiveRate,
  //       allUserStakes: stakeData,
  //       repurchaseToken: GetFloatValue(contractdata?.userData?.repurchaseWallet / 1e18)
  //     };

  //     localStorage.setItem("contractDetails", JSON.stringify(contractAllData));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  function checkData() {
    let jsondata = localStorage.getItem("dashboardData");
    let contractlocal = localStorage.getItem("contractDetails");
    let contractDetails = JSON.parse(contractlocal);
    const data = JSON.parse(jsondata);
    Data.isDebug && console.log("data", data);
    Data.isDebug && console.log("contractDetails", contractDetails);
    if (data) {
      setDashboardData(data);
      setMyRank(data?.profile?.[0]?.my_rank);
      fetchContractData(data?.profile?.[0]?.wallet_address);
      FetchData();
    } else {
      FetchData(true);
    }
  }
  function FetchData(checkload) {
    if (checkload) {
      setLoading(true);
    }
    let userId = localStorage.getItem("userId");
    Data.isDebug && console.log("user id", userId);
    axios({
      method: "post",
      url: ApiPaths.dashboard,
      data: {
        u_id: userId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log("dash", response);
        setDashboardData(response?.data);
        Data.isDebug &&
          console.log(
            "response?.data?.profile?.[0]?.wallet_address",
            response?.data?.profile?.[0]?.wallet_address
          );
        setMyRank(response?.data?.profile?.[0]?.my_rank);
        let add = response?.data?.profile?.[0]?.wallet_address;
        fetchContractData(add);
        localStorage.setItem("dashboardData", JSON.stringify(response?.data));
        localStorage.setItem(
          "walletAddress",
          response?.data?.profile?.[0]?.wallet_address
        );
        setLoading(false);
      })
      .catch(function (response) {
        Data.isDebug && console.log(response);
        setLoading(false);
      });
  }

  return (
    <>
      {loading ? <Loader /> : null}
      {incomeInfo ? (
        <InfoPage
          updateState={IncomeInfoState}
          heading={incomeHeading}
          data={infoData}
        />
      ) : null}
      <ToastContainer />
      <section className="dashboard">
        <div className="liveRateDiv">
          <h5>Live Rate</h5>
          <div>
            <p>MJK : </p>
            <p>$ {parseFloat(dashboardLiveRate).toFixed(2)}</p>
          </div>
        </div>

        <div
          id="dashboardRefLink"
          style={{ border: "1px solid #252525" }}
          className="headerLinkDiv"
          onClick={() => CopyFromtag("profileLink")}
        >
          <div className="linktext">
            <p>your referral link</p>
            <h2 id="profileLink">{`${window.location.origin}/register?ref=${dashboardData?.profile?.[0]?.username}`}</h2>
          </div>
          <i>
            <FiCopy />
          </i>
          <ReferralComponent
            link={`${window.location.origin}/register?ref=${dashboardData?.profile?.[0]?.username}`}
          />
        </div>
        <section className="cappingSection mt-4">
          <div className="viewCappingDiv ">
            <h1 className="textHeading">MJK Staking</h1>
            <Link to="staking_details" className="mb-2">
              <p>View All</p>
            </Link>
          </div>
          {dashboardData != null && dashboardData ? (
            <MyChart
              className="dashboardChart"
              stake={tokenStake}
              mined={tokenMined}
              claimed={tokenClaimed}
              repurchase={repurchaseToken}
            />
          ) : (
            ""
          )}
        </section>
        <section className="cappingSection mt-4">
          <div className="viewCappingDiv ">
            <h1 className="textHeading">USDT Staking</h1>
            <Link to="usdtstaking_details" className="mb-2">
              <p>View All</p>
            </Link>
          </div>
          {dashboardData != null && dashboardData ? (
            <UsdtChart
              className="dashboardChart"
              stake={parseFloat(usdtStakesData / 1e18).toFixed(2)}
              mined={parseFloat(usdtMine / 1e18).toFixed(2)}
              claimed={parseFloat(usdtClaim / 1e18).toFixed(2)}
            />
          ) : (
            ""
          )}
        </section>
        <h1 className="textHeadingWithMargin">Dashboard</h1>
        <Row>
          <Col lg="8">
            <Row style={{ height: "100%" }}>
              <Col md="6" className="mb">
                <div className="dashboardMainAccountCard d-flex flex-column justify-content-between">
                  <h5 className="dashboardCardHeading">User Account</h5>
                  <div className="metaDiv">
                    {/* <div>
                      <img src={User} alt="" />
                    </div> */}
                    <div className="dashboardProfile">
                      <p>Username:</p>
                      <p>{dashboardData?.profile?.[0]?.username}</p>
                    </div>
                    <div className="dashboardProfile">
                      <p>Name:</p>
                      <p>{dashboardData?.profile?.[0]?.name}</p>
                    </div>
                    <div className="dashboardProfile">
                      <p>Joining Date:</p>
                      <p>{dashboardData?.profile?.[0]?.added_on}</p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="d-flex gap-2"></div>
                  </div>
                </div>
              </Col>
              <Col md="6" className="mb">
                <div className="dashboardRankCard">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h5 className="dashboardCardHeading m-0">System Stats</h5>
                  </div>
                  <Link to="reward">
                    <img src={Logo}></img>
                  </Link>
                  <div className="aboutDetails">
                    <p>Coin Live Rate</p>
                    <h5>$ {dashboardLiveRate}</h5>
                  </div>
                  {/* <div className="aboutDetails">
                    <p>Total Users</p>
                    <h5>{totalUsers}</h5>
                  </div> */}
                  {/* <div className="aboutDetails">
                    <p>Total Staking</p>
                    <h5>
                      {parseFloat(totalStaking ?? 0).toFixed(2)}
                      {Data.coinName}
                    </h5>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            lg="4"
            className="gap-2 d-flex flex-column justify-content-between"
          >
            <Row>
              {dashboardData?.incomes &&
                Object.entries(dashboardData?.incomes).map(([key, value]) => (
                  <div className="dashboardIncomeCard" key={key}>
                    <div>
                      <h5 className="dashboardCardHeading">
                        {key.replace(/_/g, " ")}
                      </h5>
                      <h1>{parseFloat(value ?? "0").toFixed(2)}</h1>
                    </div>
                  </div>
                ))}
            </Row>
          </Col>
        </Row>

        <section className="mt-3">
          <Row>
            <Col lg="4" className="mb-2">
              <div className="wallets">
                <div className="walletsIconDiv">
                  <div className="walletsImg">
                    {" "}
                    <img src={Wallet} alt="" />
                    <h1>Wallets</h1>
                  </div>
                </div>
                <div className="walletsData">
                  <p>BNB Wallet</p>
                  <p>{GetFloatValue(walletBalance?.bnb ?? 0)} BNB</p>
                </div>
                <div className="walletsData">
                  <p>USDT Wallet</p>
                  <p>{GetFloatValue(walletBalance?.usdt ?? 0)} USDT</p>
                </div>
                <div className="walletsData">
                  <p>Minjok Wallet</p>
                  <p>{GetFloatValue(walletBalance?.token ?? 0)} MJK</p>
                </div>
              </div>
            </Col>
            <Col lg="4" className="mb-2">
              <div className="wallets">
                <div className="walletsIconDiv">
                  <div className="walletsImg">
                    {" "}
                    <img src={Team} alt="" />
                    <h1>Team</h1>
                  </div>
                </div>
                <div className="walletsData">
                  <p>Active Directs</p>
                  <p>{dashboardData?.teams?.active_directs ?? 0}</p>
                </div>
                <div className="walletsData">
                  <p>Inactive Directs</p>
                  <p>{dashboardData?.teams?.inactive_directs ?? 0}</p>
                </div>
                <div className="walletsData">
                  <p>Total Team</p>
                  <p>{dashboardData?.teams?.total_gen ?? 0}</p>
                </div>
                <div className="walletsData">
                  <p>Active Team</p>
                  <p>{dashboardData?.teams?.active_gen ?? 0}</p>
                </div>
              </div>
            </Col>
            <Col lg="4" className="mb-2">
              <div className="wallets">
                <div className="walletsIconDiv">
                  <div className="walletsImg">
                    <img src={Meating} alt="" />
                    <h1>Business Statistics</h1>
                  </div>
                </div>

                <div className="walletsData">
                  <p>Directs</p>
                  <p>
                    {parseFloat(
                      dashboardData?.business_statistics?.directs
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="walletsData">
                  <p>First Level</p>
                  <p>
                    {parseFloat(
                      dashboardData?.business_statistics?.level
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="walletsData">
                  <p>Second Level</p>
                  <p>
                    {parseFloat(
                      dashboardData?.business_statistics?.leve2
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="walletsData">
                  <p>Third Level</p>
                  <p>
                    {parseFloat(
                      dashboardData?.business_statistics?.leve3
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <h1 className="textHeadingWithMargin">Telegram</h1>
        <div className="telegramBotDiv">
          <Row className="align-items-center">
            <Col md="6">
              <div id="telegramBotDivText">
                <i>
                  <FaTelegramPlane />
                </i>
                <div>
                  <h5>Minjok Notifier</h5>
                  <p>New partners and transactions notifications</p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <button
                onClick={() =>
                  window.open(dashboardData?.telegram_link, "_blank")
                }
                className="btnPrimary"
              >
                Connect
              </button>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
