import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export default async function UsdtMined(id) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(Data.providerForUsdt);
    const signer = provider.getSigner(id);
    const contractInstance = new ethers.Contract(
      ContractDetails.contract_usdt,
      ContractDetails.contractABI_usdt,
      signer
    );

    const allMined = await contractInstance.userMined(id);
    console.log("allMined usermineddddddddddddddddddusdt data :", allMined);
    return allMined;
  } catch (error) {
    console.error("Error fetching contract UsdtMined:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
}
